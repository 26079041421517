import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Navbar from '../navbar/navbar';
import { CallToActionButton } from '../common/Button';
import withDiagonalBackground from '../common/withDiagonalBackground';
import BaseContainer from '../common/BaseContainer';
import { StaticImage } from 'gatsby-plugin-image';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import FiberpayLogoImage from '../common/FiberpayLogoImage';

const Wrapper = styled(withDiagonalBackground('div'))(() => ({
  minHeight: '100vh',
}));

const subtitles = [
  <>
    Wszystko czego potrzebujesz do <strong>bezpiecznej</strong> obsługi
    płatności&nbsp;online&nbsp;(BLIK,&nbsp;paybylink), KYC, AML i MFA.
  </>,
  <>
    W jednym miejscu, z <strong>wygodnym</strong> REST API i naszym{' '}
    <strong>przyjaznym</strong> wsparciem.
  </>,
];

const HeroImageWrapper = styled('div')(() => ({
  position: 'absolute',
  right: 0,
  zIndex: 0,
}));

const HeroPad = styled('div')(({ theme }) => ({
  [theme.breakpoints.up(2047)]: {
    paddingTop: '160px',
  },
}));

const Hero = () => (
  <Wrapper sx={{ '&::before': { display: { xs: 'none', lg: 'block' } } }}>
    <HeroImageWrapper sx={{ display: { xs: 'none', xl: 'block' } }}>
      <StaticImage
        src='../../images/hero_image.png'
        alt='Hero image'
        placeholder='blurred'
      />
    </HeroImageWrapper>
    <BaseContainer>
      <Navbar
        LogoImageComponent={FiberpayLogoImage}
        loginLink={'https://pay.fiberpay.pl/login'}
      />
      <Typography
        variant='h2'
        component='h1'
        sx={{
          fontSize: { xs: '37px', sm: '4rem', md: '5rem', lg: '6rem' },
          fontWeight: 'bold',
          mt: { xs: 3, sm: 8 },
          zIndex: 100,
        }}
        color='white'
      >
        Płatności z <br />
        supermocami
      </Typography>
      <Grid container sx={{ my: 4 }} spacing={2}>
        {subtitles.map((subtitle, idx) => (
          <Grid item xs={12} key={idx}>
            <Typography
              variant='subtitle1'
              sx={{
                color: 'white',
                fontWeight: 'light',
                fontSize: { xs: '15px', sm: '16px' },
              }}
            >
              {subtitle}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: 'flex',
          justifyContent: { xs: 'center', lg: 'flex-start' },
        }}
      >
        <a
          href='https://pay.fiberpay.pl/en/register'
          style={{ textDecoration: 'none' }}
        >
          <CallToActionButton
            endIcon={<ArrowForwardOutlinedIcon />}
            sx={{
              mt: { xs: 3, sm: 8 },
              mb: { xs: 3, lg: 0 },
              fontSize: { xs: '14px', sm: '16px', md: '18px', lg: '20px' },
              paddingY: { xs: '10px', sm: '12px', md: '14px', lg: '16px' },
              paddingX: { xs: '15px', sm: '32px', md: '48px', lg: '64px' },
            }}
          >
            Zarejestruj się
          </CallToActionButton>
        </a>
      </Box>
      <HeroPad>
        <Grid
          container
          justifyContent='center'
          sx={{
            my: 4,
            color: { xs: 'white', lg: 'black' },
            paddingTop: { xs: '20px', lg: '0' },
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              zIndex: 10,
              fontSize: { xs: '15px', md: '16px' },
            }}
          >
            Nasi klienci przyjęli już ponad
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              zIndex: 10,
              fontSize: { xs: '24px', md: '32px' },
              fontWeight: 'bold',
            }}
          >
            20.500.000 zł
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: 'center',
              zIndex: 10,
              fontSize: { xs: '15px', md: '16px' },
            }}
          >
            płatności przy użyciu naszej technologii
          </Grid>
        </Grid>
      </HeroPad>
    </BaseContainer>
  </Wrapper>
);

export default Hero;
