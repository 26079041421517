import React from 'react';
import { Grid } from '@mui/material';
import Gist from 'react-gist';
import Button from '../common/Button';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import LandingSection from './LandingSection';

const ForDevelopers = () => (
  <LandingSection
    columnText={true}
    id='dla-programistow'
    inverted
    subtitle='Zbudowane dla programistów'
    title='Obsługa płatności w Twojej aplikacji - szybko i bez komplikacji'
    colLeftText={
      <>
        Łatwo integruj i automatyzuj płatności, weryfikację klientów,
        autoryzację transakcji i ocenę ryzyka dzięki jednolitemu, prostemu
        w&nbsp;użyciu REST / JSON API.
      </>
    }
    colRightText={
      <>
        Debuguj i monitoruj działanie API za pomocą przejrzystego i wygodnego
        panelu administracyjnego. Przeglądaj logi wywołań API oraz historię
        przekazanych do Twojej aplikacji callbacków.
      </>
    }
    actionButtonText='Zobacz dokumentację API'
    actionButtonUrl='https://github.com/fiberpay/api'
    isActionUrlExternal={true}
  >
    <Grid container spacing={2} mt={4}>
      <Grid item xs={12} sx={{ pointerEvents: 'none', minHeight: "561px" }}>
        <Gist id='709597044c5283e2069408a243b80d9e' />
      </Grid>
      <Grid item xs={12}>
        <Button
          component='a'
          href='https://github.com/fiberpay/fiberpay-php'
          target='_blank'
          rel='noopener noreferrer'
          sx={{ textDecoration: 'none', textAlign: 'center' }}
          endIcon={<ArrowForwardOutlinedIcon />}
        >
          Skorzystaj z gotowego klienta PHP/JS (Github)
        </Button>
      </Grid>
    </Grid>
  </LandingSection>
);

export default ForDevelopers;
