import React from "react";
import { Container, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";

const CardFooter = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.darker,
}));

const BaseCard = ({ cardFooter, children, paperProps, ...props }) => (
  <Container maxWidth='lg' {...props}>
    <Paper
      // variant='outlined'
      {...paperProps}
      elevation={props.elevation}
      sx={{
        my: 4,
        borderRadius: "16px",
        backgroundColor: "rgb(255,255,255, 0.9)",
        overflow: "hidden",
        ...(paperProps?.sx || {}),
      }}
    >
      <Grid container item>
        {children}
        <Grid item xs={12}>
          <CardFooter>{cardFooter}</CardFooter>
        </Grid>
      </Grid>
    </Paper>
  </Container>
);

export default BaseCard;
