import React from "react";
import LandingSection from "./LandingSection";

const Support = () => (
  <>
    <LandingSection
      columnText={true}
      inverted
      subtitle='Entuzjastyczne wsparcie'
      title='Twój sukces jest dla nas ważny'
      colLeftText='Wymogi regulacyjne mogą być niejasne i szybko się zmieniają - doradzimy Ci,
      jak szybko, bezpiecznie i zgodnie z prawem przetwarzać płatności Twoich klientów.'
      colRightText='Chcemy, żeby Twój projekt się udał. Zależy nam na tym, aby Twój biznes odniósł sukces,
      dlatego pomożemy Ci w planowaniu Twojego rozwiązania oraz wesprzemy przy jego integracji.'
      // actionButtonText='Porozmawiajmy o Twoim projekcie'
      // actionButtonUrl='/404' //TODO uzupełnić
    />
  </>
);

export default Support;
