import React from 'react';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import PhonelinkLockOutlinedIcon from '@mui/icons-material/PhonelinkLockOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LandingSection from './LandingSection';
import fiberpayLogo from '../../images/fiberpay_logo.svg';
import { lighten } from '@mui/material/styles';
import Features from './Features';
import PaymentIcon from '@mui/icons-material/Payment';

const products = [
  {
    name: 'FiberPay',
    description: (
      <>
        Przyjmuj płatności za pomocą szybkich przelewów (pay-by-link),
        <br /> BLIK i kart płatniczych. Wysyłaj płatności masowe.
      </>
    ),
    icon: <CurrencyExchangeIcon sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/',
  },
  {
    name: 'FiberID',
    description: (
      <>
        Weryfikuj tożsamość klientów, których obsługujesz,
        <br />
        zapobiegaj anonimowości użytkowników.
      </>
    ),
    icon: <BadgeOutlinedIcon sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/produkty/weryfikacja-tozsamosci-klienta-fiberid',
  },
  {
    name: 'System AML',
    description: (
      <>
        Zautomatyzuj ocenę ryzyka klientów i transakcji,
        <br />
        obniż koszty zgodności z wymogami regulacyjnymi.
      </>
    ),
    icon: <PersonSearchOutlinedIcon sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/produkty/system-aml',
  },
  {
    name: 'FiberToken',
    description: (
      <>
        Zwiększ bezpieczeństwo swoich użytkowników
        <br />
        dzięki autoryzacji operacji w dedykowanej aplikacji mobilnej.
      </>
    ),
    icon: <PhonelinkLockOutlinedIcon sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/produkty/silne-uwierzytelnienie-2FA-fibertoken',
  },
  {
    name: 'Zapłatomat',
    description: (
      <>
        W Zapłatomacie wygenerujesz płatności online dla Twoich klientów
        <br />a system przypomni im o terminie zapłaty
      </>
    ),
    icon: <PaymentIcon sx={{ fontSize: { xs: 40, md: 60 } }} />,
    url: '/produkty/zaplatomat',
  },
];

const Products = () => (
  <LandingSection
    columnText={true}
    sx={{
      background: (
        theme
      ) => `url(${fiberpayLogo}) no-repeat center, linear-gradient(
    90deg,
    ${lighten(theme.palette.primary.lighter, 0.2)} 0%,
    ${theme.palette.primary.darker} 100%)`,
      marginLeft: 0,
      marginRight: 0,
    }}
    id='produkty'
    subtitle='Wszystko w jednym miejscu'
    title='Nie trać czasu na integrację rozwiązań od wielu dostawców'
    colLeftText='Sprawnie zbuduj Twoje procesy onboardingu klientów, weryfikacji tożsamości, oceny
          ryzyka, autoryzacji transakcji, wielokanałowego przyjmowania płatności, naliczania prowizji od transakcji oraz
          dystrybucji wypłat - wszystko na jednej, łatwej w obsłudze platformie technologicznej.'
    colRightText='Obniż koszty budowy i utrzymania aplikacji oraz zapewnij bezpieczeństwo i zgodność z
          obowiązującymi regulacjami - bez rozwiązywania problemów ze współpracą rozwiązań od wielu różnych
          dostawców.'
    actionButtonText='Zacznij od płatności'
    actionButtonUrl={'https://pay.fiberpay.pl/en/register'}
    isActionUrlExternal={true}
  >
    <Features
      products={products}
      largeNum={4}
      xlNum={12 / 5}
      buttonOn={true}
      avatarOff={true}
    />
  </LandingSection>
);

export default Products;
