import React from 'react';
import Hero from '../components/landing/Hero';
import SubsidyInfoBanner from '../components/landing/SubsidyInfoBanner';
import Footer from '../components/common/Footer';
import ForDevelopers from '../components/landing/ForDevelopers';
import Products from '../components/landing/Products';
import Pricing from '../components/landing/Pricing';
import EuSubsidyInfo from '../components/landing/EuSubsidyInfo';
import Compliance from '../components/landing/Compliance';
import Support from '../components/landing/Support';
import Testimonials from '../components/landing/Testimonials';
import Seo from '../seo';

const IndexPage = () => {
  return (
    <main>
      <Seo
        title='Usługi płatnicze, bramka i system płatności PayByLink, Blik | Fiberpay'
        description='Przyjmuj płatności za pomocą szybkich przelewów (pay-by-link),
        BLIK i kart płatniczych. Wysyłaj płatności masowe.'
        metaImage='meta_image_fiberpay.png'
        currentUrl='https://fiberpay.pl'
      />
      <SubsidyInfoBanner />
      <Hero />
      <ForDevelopers />
      <Products />
      <Support />
      <Testimonials />
      <Compliance />
      <Pricing />
      <EuSubsidyInfo />
      <Footer />
    </main>
  );
};

export default IndexPage;
