import React, { useCallback, useEffect, useRef, useState } from 'react';
import BaseCard from '../common/BaseCard';
import { Box, Grid, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import Link from '../common/Link';

const data = [
  {
    image: (
      <StaticImage
        src='../../images/vindicat.png'
        alt='Vindicat'
        imgStyle={{ borderRadius: '50%' }}
        placeholder='blurred'
      />
    ),
    text:
      'Integracja z bramką płatniczą Fiberpay pozwoliła nam zautomatyzować całość procesu obsługi wierzytelności naszych klientów. ' +
      'Fiberpay obsługuje naliczanie prowizji oraz przekazuje wpłacone środki bezpośrednio na konto bankowe wierzyciela.',
    caption: 'Bogusław Bieda, prezes Vindicat',
    link: {
      text: 'Sprawdź Vindicat',
      to: 'https://vindicat.pl/',
    },
  },
  {
    image: (
      <StaticImage
        src='../../images/domtel.png'
        alt='Domtel'
        imgStyle={{ borderRadius: '50%' }}
        placeholder='blurred'
      />
    ),
    text:
      'Zintegrowaliśmy bramkę płatniczą Fiberpay z naszą aplikacją mobilną, aby ułatwić klientom opłacanie abonamentu za usługi telekomunikacyjne. ' +
      'Klienci mogą teraz uregulować należności bezpośrednio w aplikacji - przelewem online lub BLIK.',
    caption: 'Dariusz Dombek, prezes Domtel Telekom',
    link: {
      text: 'Sprawdź Domtel Telekom',
      to: 'https://domtel.com.pl/',
    },
  },
  {
    image: (
      <StaticImage
        src='../../images/marianna_klosinka.png'
        alt='marianna_klosinka'
        imgStyle={{ borderRadius: '50%' }}
        placeholder='blurred'
      />
    ),
    text: (
      <>
        Dzięki wykorzystaniu Fiberpay, zautomatyzowaliśmy przyjmowanie płatności
        za rezerwacje na Wioski Bullerbyn oraz przyjmowanie comiesięcznych
        płatności za czesne w naszej szkole. <br />
      </>
    ),

    caption: 'Marianna Kłosińska, prezeska Fundacji Bullerbyn',
    link: {
      text: 'Sprawdź Fundacje Bullerbyn',
      to: 'https://bullerbyn.org.pl/',
    },
  },
];

const Quote = ({ children }) => (
  <Typography
    component='em'
    fontSize={{ xs: '14px', sm: '16px' }}
    sx={{
      position: 'relative',
      fontWeight: 300,
      lineHeight: 1.75,
      letterSpacing: '0.02em',

      m: 0,
      // '&::before, &::after': {
      //   content: '""',
      //   backgroundImage: `url(${withPrefix('/comma.png')}`,
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   width: '100px',
      //   height: '100px',
      //   border: '2px solid red',
      // },
    }}
  >
    {children}
  </Typography>
);

const Testimonial = ({ data, ...props }) => {
  const { image, text, caption, link } = data;

  return (
    <BaseCard maxWidth='md' {...props} elevation={4}>
      <Grid
        container
        p={{ xs: 1, md: 4 }}
        marginX={{ sm: '25px', md: '40px' }}
        component='figure'
        justifyContent='center'
      >
        <Grid
          item
          display={{ sm: 'block', md: 'block' }}
          md={4}
          sm={3}
          alignItems='center'
          marginBottom='15px'
          width={{ xs: '100px', sm: 'auto' }}
        >
          {image}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={9}
          md={8}
          spacing={2}
          paddingLeft={{ sm: '25px', md: '0' }}
          component='blockquote'
        >
          <Grid item xs={12} textAlign={{ xs: 'center', sm: 'left' }}>
            <Quote>
              {text} <br />
            </Quote>
          </Grid>
          <Grid item xs={12} textAlign={{ xs: 'center', sm: 'left' }}>
            <Typography
              variant='subtitle2'
              sx={{
                fontWeight: 800,
                fontStyle: 'normal',
                fontSize: '12px',
                lineHeight: '18px',
              }}
              component='cite'
            >
              - {caption}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            textAlign={{ xs: 'center', sm: 'end' }}
            component='cite'
            sx={{ fontStyle: 'normal' }}
            marginTop={{ xs: '10px', sm: '0' }}
          >
            <Link
              component='a'
              href={link.to}
              target='_blank'
              rel='noopener noreferrer sponsored'
              sx={{
                fontStyle: 'normal',
                fontWeight: 800,
                fontSize: '12px',
                textDecoration: 'underline',
              }}
            >
              {link.text} {'>>'}
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </BaseCard>
  );
};

const onSideSx = {
  filter: 'blur(2px)',
  position: 'absolute',
  top: '35%',
  opacity: 0.8,
  zIndex: -1,
  display: { xs: 'none', xl: 'block' },
};

const getControlStyles = (isActive) => ({
  margin: '8px',
  borderRadius: '50%',
  width: '15px',
  height: '15px',
  border: '1px solid rgba(44, 62, 84, .1)',
  backgroundColor: isActive ? '#A6C9F2' : '#DEEBFA',
  cursor: 'pointer',
});

const HANDLER_INTERVAL = 10000;

const Testimonials = () => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const handler = useRef(null);

  const getNextIdx = (idx) => (idx + 1) % data.length;
  const getPrevIdx = (idx) => (idx - 1 + data.length) % data.length;

  const handleNext = useCallback(() => setCurrentIdx(getNextIdx), []);

  useEffect(() => {
    handler.current = setInterval(handleNext, HANDLER_INTERVAL);
    return () => clearInterval(handler.current);
  }, [handleNext]);

  const handleSetIndex = (idx) => {
    clearInterval(handler.current);
    handler.current = setInterval(handleNext, HANDLER_INTERVAL);
    setCurrentIdx(idx);
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Box
        sx={{
          position: 'relative',
          overflow: 'visible',
          marginBottom: { xl: '10%' },
        }}
      >
        <Testimonial
          data={data[getPrevIdx(currentIdx)]}
          sx={{ ...onSideSx, left: '-10%' }}
        />
        <Testimonial data={data[currentIdx]} />
        <Testimonial
          data={data[getNextIdx(currentIdx)]}
          sx={{ ...onSideSx, right: '-10%' }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: { xs: '32px', sm: '64px' },
        }}
      >
        {data.map((d, idx) => (
          <Box
            key={idx}
            style={getControlStyles(idx === currentIdx)}
            onClick={() => handleSetIndex(idx)}
          />
        ))}
      </Box>
    </div>
  );
};

export default Testimonials;
