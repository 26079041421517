import React from 'react';
import { Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import BaseContainer from '../common/BaseContainer';
import { Link as GatsbyLink } from 'gatsby';
import { Box } from '@mui/system';

const SubsidyInfoBanner = () => (
  <div style={{ backgroundColor: 'white' }}>
    <BaseContainer
      sx={{
        display: 'flex',
        alignItems: { xs: 'flex-start', md: 'center' },
        justifyContent: { xs: 'flex-end', md: 'space-between' },
        paddingLeft: { md: '21.5px' },
        paddingRight: { xs: '21px', sm: '26px', md: '26px', lg: '16.5px' },
      }}
    >
      <Typography
        sx={{
          color: 'primary.light',
          p: 1,
          fontSize: '10px',
          display: { xs: 'none', md: 'flex' },
        }}
      >
        FiberPay sp. z o.o. realizuje projekt dofinansowany z funduszy
        Europejskich “Automatyzacja procesu zgłaszania informacji do GIIF”
      </Typography>
      <Box
        sx={{
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <GatsbyLink to='/#dofinansowanie'>
          <StaticImage
            src='../../images/eu_logotype.jpg'
            alt='Unia Europejska'
            objectFit={'contain'}
            width={142}
          />
        </GatsbyLink>
      </Box>
      <Box
        sx={{
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <GatsbyLink to='/#dofinansowanie'>
          <StaticImage
            src='../../images/eu_logotype.jpg'
            alt='Unia Europejska'
            objectFit={'contain'}
            width={122}
          />
        </GatsbyLink>
      </Box>
    </BaseContainer>
  </div>
);

export default SubsidyInfoBanner;
