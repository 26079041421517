import React from 'react';
import { Box, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import LandingSection from './LandingSection';

const EuSubsidyInfo = () => (
  <>
    <LandingSection
      columnText={true}
      inverted
      id='dofinansowanie'
      subtitle='Informacja o projekcie'
      title='„Automatyzacja procesu zgłaszania informacji do GIIF”'
      sx={{ pb: 2 }}
      colLeftText={
        <>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            Przedmiotem projektu jest znaczące ulepszenie usługi masowego
            przyjmowania wpłat i masowej dystrybucji środków pieniężnych poprzez
            usprawnienie procesu zgłaszania transakcji podejrzanych, łączonych i
            ponadprogowych do Generalnego Inspektora Informacji Finansowej
            (GIIF).
          </Typography>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            Celem projektu jest automatyzacja procesu analizy danych transakcji
            i wzrost kontroli nad całym procesem przekazywania informacji w
            zakresie przeciwdziałania praniu pieniędzy i finansowaniu
            terroryzmu.
          </Typography>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            Projekt jest współfinansowany w ramach Europejskiego Funduszu
            Rozwoju Regionalnego ze środków Programu Operacyjnego Inteligentny
            Rozwój 2014-2020.
          </Typography>
        </>
      }
      colRightText={
        <>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            Oś priorytetowa II: Wsparcie otoczenia i potencjału przedsiębiorstw
            do prowadzenia działalności B+R+I Działanie 2.3 Proinnowacyjne
            usługi dla przedsiębiorstw Poddziałanie 2.3.2 Bony na innowacje dla
            MŚP
          </Typography>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            <b>Beneficjent:</b> FIBERPAY SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ
          </Typography>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            <b>Całkowita wartość projektu: </b>488 886,51 PLN
          </Typography>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            <b>Wartość dofinansowania:</b> 337 848.43 PLN
          </Typography>
          <Typography paragraph sx={{ fontSize: { xs: '14px', md: '16px' } }}>
            <b>Okres realizacji:</b> styczeń 2022 – listopad 2022
          </Typography>
        </>
      }
    />
    <Box
      textAlign='center'
      sx={{ backgroundColor: 'white', py: { xs: 0.5, md: 4 } }}
    >
      <StaticImage
        src={'../../images/eu_funds_banner.png'}
        alt='Fundusze Europejskie Inteligentny Rozwój'
        placeholder='blurred'
      />
    </Box>
  </>
);

export default EuSubsidyInfo;
