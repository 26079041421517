import { Grid, Typography } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import LandingSection from './LandingSection';
import { lighten } from '@mui/material/styles';
import KnfBackground from '../../images/knf_background.png';
import { Box } from '@mui/material';

const Compliance = () => (
  <>
    <Box
      sx={{
        height: { xs: '32px', sm: '96px' },
        width: '100%',
        zIndex: -1,
        background: (theme) => `linear-gradient(
    90deg,
    ${theme.palette.primary.darker} 0%,
    ${lighten(theme.palette.primary.lighter, 0.2)} 100%)`,
      }}
    />
    <LandingSection
      columnText={true}
      inverted
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
      id='branze'
      title='Twój biznes jest w dobrych rękach'
      subtitle='Bezpieczeństwo i zgodność z regulacjami'
      colLeftText='Pomożemy Ci w szczegółowej analizie ryzyk regulacyjnych związanych z Twoim modelem rozliczeń
      i zaproponujemy optymalny, zgodny z prawem model korzystania z usług płatniczych.'
      colRightText='Twój biznes jest z nami bezpieczny - spełniamy wymogi prawno-regulacyjne,
      współpracujemy z doświadczonymi partnerami oraz zabezpieczamy naszą technologię przy pomocy sprawdzonych technik i narzędzi.'
      // actionButtonText='Przykładowe projekty'
      // actionButtonUrl='/404' //TODO uzupełnić
    >
      <Grid
        container
        sx={{
          mt: 2,
          py: { xs: 1, md: 8 },
          position: 'relative',
          background: `url(${KnfBackground}) no-repeat center`,
          flexDirection: { xs: 'column-reverse', md: 'row' },
        }}
        spacing={3}
      >
        <Grid item xs={12} md={6} textAlign={{ xs: 'center', md: 'end' }}>
          <StaticImage
            src={'../../images/knf_logo.png'}
            alt='KNF'
            placeholder='blurred'
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
            color: 'primary.light',
            textAlign: { xs: 'center', md: 'left' },
          }}
        >
          <Typography
            width='100%'
            sx={{
              marginBottom: { xs: '0', md: '-8px' },
              fontSize: { xs: '15px', md: '17px' },
            }}
          >
            <b>Nasze płatności są nadzorowane przez KNF</b>
          </Typography>
          <Typography sx={{ fontSize: { xs: '12px', md: '14px' } }}>
            Wpis do rejestru małych instytucji płatniczych: MIP28/2019
          </Typography>
        </Grid>
      </Grid>
    </LandingSection>
  </>
);

export default Compliance;
