import { Grid, Typography } from '@mui/material';
import Button from '../common/Button';
import React from 'react';
import LandingSection from './LandingSection';
import BaseContainer from '../common/BaseContainer';
import BaseCard from '../common/BaseCard';
import FiberpayLogoLight from '../../images/fiberpay_logo_light.png';
import { ArrowForwardIosOutlined } from '@mui/icons-material';
import EndInfoBanner from './EndInfoBanner';

const textUp = 'Zacznij już teraz';

const textDown = (
  <>
    Zarejestruj się, aby przyjmować płatności w Twojej aplikacji
    <br />- <strong>szybko</strong>, <strong>bezpiecznie</strong> i zgodnie{' '}
    <strong>z prawem</strong>!
  </>
);

const textButton = <> Zarejestruj&nbsp;się </>;

const pageLink = 'https://pay.fiberpay.pl/en/register';

const Pricing = () => (
  <>
    <LandingSection
      columnText={true}
      id='cennik'
      subtitle='API na pierwszym miejscu'
      title='Automatyzuj przetwarzanie płatności Twoich użytkowników'
      colLeftText='Przyjmuj wpłaty, naliczaj prowizje od transakcji i przesyłaj płatności do odbiorców
        - zautomatyzowane za pomocą API i bez ręcznych rozliczeń, czy przelewów.'
      colRightText='Buduj rozwiązania typu marketplace umożliwiające rozliczenia pomiędzy użytkownikami,
      automatyzujące przyjmowanie należności lub ułatwiające masową dystrybucję środków do odbiorców.'
      // actionButtonText='Przykładowe projekty'
      // actionButtonUrl='/404' //TODO uzupełnić
    />
    <div>
      <BaseContainer sx={{ my: { xs: 4, md: 6 } }}>
        <BaseCard
          paperProps={{
            sx: {
              filter: 'drop-shadow(0px 9px 4px rgba(0, 0, 0, .35))',
              borderRadius: '20px',
              border: '1px solid #FFFFFF',
              borderBottom: '1px solid #F2F2F2',
              background: `url(${FiberpayLogoLight}) no-repeat center white`,
            },
          }}
          cardFooter={
            <Typography
              variant='body2'
              textAlign='center'
              sx={{
                py: 1,
                color: 'neutral.dark',
                fontSize: { xs: '11px', md: '12px' },
                marginLeft: '10px',
                marginRight: '10px',
              }}
            >
              0% PROWIZJI PRZEZ 1 MIESIĄC - BEZ ZOBOWIĄZAŃ
            </Typography>
          }
        >
          <Grid
            container
            item
            textAlign='center'
            xs={12}
            spacing={1}
            sx={{ mb: { xs: 2, md: 4 }, mt: 2 }}
          >
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '14px', md: '16px' },
                  marginLeft: '16px',
                  marginRight: '16px',
                }}
              >
                Koszt obsługi płatności Twoich klientów to tylko
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant='h3'
                sx={{
                  fontWeight: 700,
                  position: 'relative',
                  '&::after': {
                    content: '"(min. 2 PLN)"',
                    position: 'absolute',
                    fontSize: '10px',
                    fontWeight: '400',
                    color: 'neutral.dark',
                    bottom: { xs: '8px', md: '12px' },
                  },
                  fontSize: { xs: '38px', md: '48px' },
                }}
              >
                2.0%
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: { xs: '14px', md: '16px' },
                  marginLeft: '24px',
                  marginRight: '24px',
                }}
              >
                Bez stałych opłat. Bez ukrytych kosztów.
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: 'none' }}>
              <Button
                sx={{ mt: 3, mb: 2 }}
                variant='contained'
                endIcon={<ArrowForwardIosOutlined />}
              >
                Wypróbuj
              </Button>
            </Grid>
          </Grid>
        </BaseCard>
      </BaseContainer>
    </div>
    <EndInfoBanner
      textUp={textUp}
      textUpFoWe={700}
      textDown={textDown}
      textDoFoWe={500}
      textButton={textButton}
      pageLink={pageLink}
    />
  </>
);

export default Pricing;
